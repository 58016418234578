@import "../all/colors.scss";
.slide-right {
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;

  .side-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 50%;
    justify-content: flex-end;
    background: $ab-black;
    align-items: flex-end;
  }

  .bg-right {
    background: linear-gradient(
      -90deg,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0)
    );
  }

  .bg-hell {
    background: linear-gradient(
      180deg,
      rgba(255, 81, 0, 0) 0%,
      rgba(255, 81, 0, 1) 100%
    );
  }

  /* CONTENIDO SEGUNDA DIAPO */
  .box-2 {
    color: $ab-white !important;
    max-width: 23rem;
    margin-right: 8.375rem;
    margin-bottom: 11.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
  }

  @media only screen and (max-width: 768px) {
    .side-content {
      width: 100%;
      align-items: center;
      justify-content: flex-end;
    }
    .box-2 {
      max-width: 90%;
      margin: 0px 0px 3.5rem 0px;
    }
    .bg-left,
    .bg-right {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

.box-3 {
  color: $ab-white !important;
  margin: 0 4.3125rem 11.625rem 6rem;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    margin: 0px 0px 3.5rem 0px;
  }
  & > p {
    margin-top: 3.5rem;
  }
}
