@import "../components/all/colors.scss";
.img-soluciones-0 {
  background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../images/soluciones/bg1.jpg");
}

.arrow-down {
  bottom: 0px;
  right: 0px;
  display: block;
  height: 448px;
  width: 38px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 38 448' style='enable-background:new 0 0 38 448;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF5100;%7D%0A%3C/style%3E%3Cpath class='st0' d='M18,446.1c1,1,2.6,1,3.5,0l15.9-15.9c1-1,1-2.6,0-3.5c-1-1-2.6-1-3.5,0l-14.1,14.1L5.6,426.7c-1-1-2.6-1-3.5,0 c-1,1-1,2.6,0,3.5L18,446.1z M17.2,0v444.4h5V0L17.2,0z'/%3E%3C/svg%3E ");
  margin-bottom: 20px;
  margin-right: 35px;
  background-repeat: no-repeat;
}

.arrow-down-sm {
  background-image: url(../images/soluciones/arrow.svg);
  background-repeat: no-repeat;
  width: 36.67px;
  height: 125.74px;
  margin-top: 5px;
}

@media only screen and (min-width: 721px) {
  .font-titan {
    font-size: 10rem;
    display: inline-block;
  }

  .percent-text {
    position: absolute;
    right: 20vw;
    bottom: -5;
  }
}

@media only screen and (max-width: 720px) {
  .font-titan {
    font-size: 10rem;
    display: inline-block;
  }

  .percent-text {
    position: absolute;
    right: 10vw;
    bottom: -5;
  }
}

@media only screen and (max-width: 359px) {
  .font-titan {
    font-size: 7rem;
    display: inline-block;
  }

  .percent-text {
    position: absolute;
    right: 10vw;
    bottom: -50;
  }
}

.h0 {
  font-size: xx-large;
}

.screen-row {
  &-light {
    min-height: 100vh;
    background-color: $ab-light-gray;
  }

  &-dark {
    min-height: 100vh;
    background-color: $ab-gray;
  }
}

.soluciones-mobile {
  .row {
    &-light {
      background-color: $ab-light-gray;
    }

    &-dark {
      background-color: $ab-gray;
    }
  }
}

.center {
  &-h {
    display: flex;
    justify-content: center;
  }

  &-v {
    display: flex;
    align-items: center;
  }

  &-all {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
