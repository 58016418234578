@import "../all/colors.scss";

.one-screen-2 {
  min-height: 100vh;
  width: 100%;
  background-color: $ab-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .admin-container {
    margin: 2rem 0 2rem 0;
    display: grid;
    grid-template-columns: 22.5rem 22.5rem;
    grid-template-rows: repeat(5, 6rem);
    gap: 10px;
    // grid-template: 'guy photo guy' 'guy photo guy' 'guy photo guy' 'guy photo guy' 'guy photo guy';
    justify-content: space-around;
    align-content: center;
  }

  .photo {
    display: flex;
    justify-content: center;
    // background-color: blueviolet;
    grid-area: "photo";
    grid-column: 2/3;
    grid-row: 1/6;
  }

  .guy {
    /* background-color: rgba(77, 0, 10, 0.6); */
    padding-left: 2rem;
    color: $ab-orange;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    font-size: 1.0625rem;
    line-height: 1.25rem;
    transition: 0.2s background-color;
    border-radius: 1rem;
  }

  .guy h2 {
    font-weight: bold;
    font-size: 1.3125rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
  }

  // .guy:hover {
  //    /* background-color: rgba(255, 81, 0, 0.2);*/
  //    color:$ab-white;
  //     cursor: pointer;
  // }

  .title,
  .title-sm-sep {
    margin: 0 auto;
    color: $ab-orange;
    width: 90%;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.625rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .title:after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background-color: $ab-orange;
  }

  .title-sm-sep:after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $ab-orange;
  }

  @media only screen and (max-width: 768px) {
    .admin-container {
      margin: 2rem 0 2rem 0;
      display: grid;
      grid-template-columns: 90%;
      grid-auto-rows: 100px;
      gap: 10px;
      justify-content: center;
    }
    .guy {
      padding: 0;
    }
  }
}
