@import "../components/all/colors.scss";
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

div.first {
  background-image: url(../images/carta/bg1.jpg);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 20% 0%;
  @media (min-width: 768px) {
    background-position: center;
  }
  background-size: cover;
}

.president {
  display: flex;
  align-items: flex-end;
  height: 200px;

  &-name {
    background: rgb(255, 81, 0);
    background: linear-gradient(
      180deg,
      rgba(255, 81, 0, 0.018644957983193322) 0%,
      rgba(255, 81, 0, 1) 100%
    );
    color: $ab-white;
    font-size: 1rem;
    padding: 10%;
  }
}

#carta {
  &-titulo {
    font-size: 1.5rem;
  }
  &-bajada {
    font-size: 1.5rem;
    margin-bottom: 30vh;
  }
  &-texto {
    margin-bottom: 50vh;
  }
}

.text-light-gray {
  color: $ab-gray;
}

.text-dark-gray {
  color: #1d1d1b;
}

.fs-6 {
  font-size: 5rem;
}

.is-mobile.carta {
  background-color: $ab-light-gray !important;
  // color: $ab-black;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;

    & > div {
      padding: 10px;
      width: 50%;
      height: 30vh;
    }
    p {
    }
  }
  .download-chapter {
    display: flex;
    height: 20vh;
    justify-content: center;
    align-items: center;
  }
}
