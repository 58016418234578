.slide-4 {
    // transition: 1s;
    display: flex;
    min-height: 100vh;
    color: #ff5506;
    font-size: 3rem;
    justify-content: center;
    align-items: center;
}

.slide-4 > div {
    // transition: 1s;
    max-width: 59.375rem;
}

@media only screen and (max-width: 768px) {
    .slide-4 {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
    .slide-4 > div {
        width: 90%;
    }
}
