@import "../all/colors.scss";

.carpa-comedor {
  // min-height: 100vh;
  width: 90%;
  margin: 0 auto;
  //   background: rgba($color: #000000, $alpha: 0.2);
  h2 {
    padding-top: 10%;
    font-size: 1.3125rem;
    line-height: 2rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #5f6468;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    color: $ab-gray;
  }
  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  p.p-variant {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.05em;
  }
  .p1 {
    width: 50%;
    padding: 20px 50px 50px 0;
  }
  .p2 {
    width: 20rem;
  }
  @media only screen and (max-width: 700px) {
    .p1 {
      width: 100%;
      padding: 10px;
      text-align: justify;
    }
    .p2 {
      display: block;
      width: 100%;
    }
  }
  .p3-footer {
    max-width: 46.875rem;
    padding: 5% 0 10% 0;
  }
  .p4 {
    max-width: 26.25rem;
    padding: 5% 0 10% 0;
  }
  img {
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
.custom-video {
  margin: 0 auto;
  transition: 1s;
  width: 80%;
  height: 80%;
  position: relative;
  // & > video {
  //   transition: 1s;
  //   width: 100%;
  // }
  &.active {
    // & > video {
    //   width: 100%;
    //   height: 100vh;
    //   overflow: hidden;
    //   object-fit: cover;
    // }
    width: 100%;
  }
  .pelota {
    border-radius: 100%;
    background: $ab-white;
    top: 0;
    height: 100px;
    width: 100px;
    position: absolute;
  }
}

.logo-slide {
  margin-bottom: 50px;
}
