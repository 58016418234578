@import "../components/all/colors.scss";
.fs-giant {
  font-size: 15vw;
}

.vl {
  border-left: 1px solid $ab-orange;
  height: 25vw;
  margin-left: 7.5%;
  margin-right: 5%;
}

.spaced {
  letter-spacing: 4px;
  font-weight: bold;
}

.vertical-gradient-liderazgo {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 25%,
    rgba(255, 81, 0, 1) 100%
  );
}

.img-liderazgo-0 {
  background-image: url("../images/liderazgo/bg0.png");
}

.img-liderazgo-1 {
  background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 75%,
      rgba(0, 0, 0, 0.75) 100%
    ),
    url("../images/liderazgo/bg1.png");
}

.img-liderazgo-2 {
  background-image: radial-gradient(
      circle at bottom left,
      rgba(255, 81, 0, 0.75) 0%,
      rgba(255, 255, 255, 0) 50%
    ),
    url("../images/liderazgo/bg2.png");
}

@media only screen and (max-width: 768px) {
  .fs-giant {
    font-size: 35vw;
  }
}
