.img-operaciones-0 {
    background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 50%,
            rgba(0, 0, 0, 0.8) 100%
        ),
        url("../images/operaciones/bg0.jpg");
}

.img-operaciones-1 {
    background-image: url("../images/operaciones/bg1.jpg");
}

.vertical-gradient-operaciones {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 81, 0, 1) 100%
    );
    height: inherit;
}
