@import "../all/colors.scss";
.primary-text {
  text-align: left;
  color: $ab-orange;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: 2vh;
  left: 40vw;
}
.secundary-text {
  text-align: left;
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: 2vh;
  left: 40vw;
}

.navbar2 {
  z-index: 1000;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 4vw;
  padding-right: 4vw;
  top: 0;
  width: 100%;
  height: 10vh;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.85);
  .logo {
    position: relative;
    height: 3vh;
    width: 25vw;
    background-color: transparent;
  }
  .descarga {
    position: relative;
    left: 20vw;
    height: 3vh;
    background-color: transparent;
  }
  .burguer {
    position: relative;
    height: 3vh;
    border: transparent;
    background: transparent;
  }
}

.navbar {
  z-index: 1000;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 4vw;
  padding-right: 4vw;
  top: 0;
  width: 100%;
  height: 10vh;
  overflow: hidden;
  background: transparent;

  .logo {
    position: relative;
    height: 3vh;
    width: 25vw;
    background-color: transparent;
  }
  .descarga {
    position: relative;
    left: 20vw;
    height: 3vh;
    background-color: transparent;
  }
  .burguer {
    position: relative;
    height: 3vh;
    border: transparent;
    background: transparent;
  }
}

.menu-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin-top: 10vh;
  .nav_item_title {
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
    vertical-align: center;
    letter-spacing: 0.12rem;
  }
  .nav_item_number {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    color: $ab-orange;
    vertical-align: center;
    letter-spacing: 0px;
  }
  .row {
    padding-top: 2vh;
    position: relative;
    align-items: center;
  }
  .col {
    margin: 2vw;
  }
}

.menu-item-mobile {
  width: 100%;
  height: 10vh;
  padding-left: 4vw;
  float: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.overlay-menu-mobile {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 150;
  background: rgb(248, 82, 4);
  background: linear-gradient(
    180deg,
    rgba(248, 82, 4, 1) 0%,
    rgba(83, 87, 90, 1) 70%
  );
  transition: 0.1s;
  transform: translateX(100vw);
  * &.active {
    transform: translateX(0vw);
  }
}
