@import "../components/all/colors.scss";
.section {
  background-color: rgb(83, 86, 89);
}
.section-title {
  color: $ab-orange;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: 0.15em;
}

.section-title.number {
  font-size: 48px;
}

.Secciones{
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.box {
  overflow: show;
  img:hover {
    transform: scale(1.15, 1.15);
    transition: all 0.4s ease-out;
  }
}

.discover-img {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transition: 1s ease-in-out;
  transform: translateY(100px);
  &::after {
    z-index: 2;
    transition: 1s linear;
    // transition-delay: 0.5s;
    content: "";
    position: absolute;
    top: 0;
    background-color: $ab-orange;
    width: 100%;
    height: 100%;
  }
  &[data-active="true"]::after {
    height: 0%;
  }
  &[data-active="true"] {
    transform: translateY(0px);
  }
  // & > img {
  //   transition: 1s;
  //   transform: translateY(100vh);
  // }
  // &[data-active='true'] > img {
  //   transform: translateY(0px);
  // }
}
