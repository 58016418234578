@import "../components/all/colors.scss";

.sosten{
  .gallery img {
    width: 100%;
   /* height: 100%;*/
    display: block;
  }
}

.sostenLogo{
    display: block;
    float:left;
}

.logo-slide{
  display: block;
}


.bg-lateral {
  background: rgb(0, 0, 0);
  &-right {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &-left {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.hell-glowing {
  background: $ab-orange;
  background: linear-gradient(
    0deg,
    rgba(251, 81, 0, 1) 0%,
    rgba(251, 81, 0, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.crop {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.crop img {
  width: 100vw;
  height: 100vh;
  margin: -75px 0 0 -100px;
}

.s5 {
  background-image: url(../images/sosten/header.jpg);
  background-position: center;
}

.transition {
  transition: 2s;
  &[data-scroll="false"] {
    opacity: 0;
    transform: translateX(-100px);
  }
  &[data-scroll="true"] {
    transform: translateX(0px);
    opacity: 1;
  }
}

.img-zoom {
  transform: scale(1);
  transition: 1s;
  &:hover {
    transform: scale(1.5);
  }
  transform: translateX(-200px);
  @media (min-width: 768px) {
    transform: translateX(0px);
  }
}

.fade-in {
  position: relative;
  opacity: 0;
  transition: 2s;
  transform: translateX(-100px);
  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
}

#posta-central {
  padding: 10%;
}
