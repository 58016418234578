@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../components/all/colors.scss";

#administracion {
  background-color: rgb(83, 87, 90);
  background: linear-gradient(
    180deg,
    rgba(255, 81, 0, 1) 0%,
    rgba(83, 87, 90, 1) 15%,
    rgba(83, 87, 90, 1) 100%
  );
  margin: 0px;
}

#directorio {
  max-width: 1200px;
}

.gang-img:hover {
  background: rgb(227, 227, 226);
  background: linear-gradient(
    180deg,
    rgba(253, 90, 14, 1) 0%,
    rgba(227, 227, 226, 0) 100%
  );
}

.gobierno .hh2 {
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(253, 90, 14, 1);
  margin-bottom: 50px;
}

#presidente {
  padding-right: 50px;
  &-img {
    display: flex;
    align-items: center;
  }
  &-title {
    padding-top: 20px;
    font-size: 1rem;
  }
  &-name {
    font-size: 0.8rem;
  }
}

.director {
  min-height: 360px;
  padding-bottom: 60px;
  margin-top: 30px;
}

.director-img {
  min-height: 100%;
}

.director-title {
  font-size: 1rem;
  padding-top: 20px;
  text-align: center;
}
.director-name {
  text-align: center;
  font-size: 0.8rem;
}

.gerente-img {
  margin-top: 200px;
}

$bullet-spacing: 3.5vw;

.orange-bullet {
  border-color: $ab-orange !important;
  background-color: $ab-orange !important;
  padding: 2.5px !important;
  box-shadow: none !important;
  border: 0 !important;
  margin: 0 $bullet-spacing 0 $bullet-spacing !important;
}

.orange-bullet:first-child {
  margin: 0 $bullet-spacing 0 0 !important;
}

.orange-bullet:last-child {
  margin: 0 0 0 $bullet-spacing !important;
}

.orange-bullet.active {
  background: radial-gradient(
    circle,
    $ab-orange 0%,
    $ab-orange-50 50%,
    $ab-gray 75%
  ) !important;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 5px !important;
}

.hline-orange {
  &-sm {
    border-top: 1px solid $ab-orange;
    opacity: 1;
    width: 100%;
    margin: 0 0 0 0;
  }

  &-lg {
    border-top: 3px solid $ab-orange;
    opacity: 1;
    width: 100%;
    margin: 0 5vw 0 5vw;
  }
}

.screen-row {
  min-height: 100vh;
  background-color: $ab-gray;

  &-light {
    min-height: 100vh;
    background-color: $ab-light-gray;
  }

  &-dark {
    min-height: 100vh;
    background-color: $ab-gray;
  }
}

.gobierno {
  --bs-gutter-x: 0 !important;

  $orange: $ab-orange;
  $gray: $ab-gray;

  .title,
  .title-sm-sep {
    margin: 0 auto;
    color: $ab-orange;
    width: 90%;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.625rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .title:after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background-color: $ab-orange;
  }

  .title-sm-sep:after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $ab-orange;
  }

  .row {
    &-light {
      background-color: $ab-light-gray;
    }

    &-dark {
      background-color: $ab-gray;
    }
  }

  .center {
    &-h {
      display: flex;
      justify-content: center;
    }

    &-v {
      display: flex;
      align-items: center;
    }

    &-all {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .gradient-overlay-orange {
    background: linear-gradient(180deg, $ab-orange-50 0%, $gray 5%);
    background-color: transparent;
  }
}
