@import "../all/colors.scss";
.slide-left {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  h2 {
    font-weight: bold;
  }
  .side-content {
    z-index: 10;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 50%; /* EDITABLE */
    align-items: flex-start;
    justify-content: flex-end;
    // background: $ab-black;
    &.short {
      width: 38%;
    }
  }

  /* CONTENIDO PRIMERA DIAPO */
  .box {
    color: $ab-white !important;
    // background: red;
    max-width: 19.5rem;
    margin-left: 5.9rem;
    margin-bottom: 5.9rem;
  }
  .box > img {
    margin-top: 5.5rem;
  }
  .box > p {
    margin-top: 3.4rem;
  }

  /* CONTENIDO SEGUNDA DIAPO */
  .box-2 {
    max-width: 21.9375rem;
    margin-right: 8.375rem;
    margin-bottom: 11.75rem;
  }

  @media only screen and (max-width: 768px) {
    .side-content {
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      &.short {
        width: 100%;
      }
    }
    .box,
    .box-2 {
      max-width: 90%;
      margin: 0px 0px 3.5rem 0px;
    }
    .bg-left,
    .bg-right {
      background: rgba(0, 0, 0, 0.5);
    }
    .box > img {
      display: none;
    }
  }
}

.bg-left {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0));
}

.bg-right {
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0));
}

.bg-hell {
  background: linear-gradient(
    180deg,
    rgba(255, 81, 0, 0) 0%,
    rgba(255, 81, 0, 1) 100%
  );
}
