@import "../all/colors.scss";

.box {
  max-width: 20.0625rem;
  margin: 0 0 7.0625rem 6.125rem;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    margin: 0 0 0 1rem;
  }

  color: $ab-white;
  & > p:nth-child(2) {
    margin-top: 2rem;
  }
  & > p:nth-child(1) {
    margin-top: 3.5rem;
  }
  & > h2 {
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
  & > p {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
  }
}

.box2 {
  max-width: 23.375rem;
  margin: 0 0 7.25rem 6.4375rem;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    margin: 0 0 0 1rem;
  }

  color: $ab-white;
  & > p:nth-child(2) {
    margin-top: 2rem;
  }
  & > p:nth-child(1) {
    margin-top: 3.5rem;
  }
  & > h2 {
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
  & > p {
    max-width: 20.0625rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
  }
}
