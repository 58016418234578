.cursor {
  position: fixed;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  background-color: #ffff;
  z-index: 100;
}
.cursor-text {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
