@import "../components/all/colors.scss";
.btn-bajar {
  margin-bottom: 20px;
  margin-left: -50px;
}

.section {
  background-color: $ab-gray;
}
.move-img-0 {
  overflow: hidden;
  animation-name: move-0, move-0-after;
  animation-timing-function: linear, linear;
  animation-iteration-count: 1;
  animation-duration: 1.5s, 18s;
  animation-delay: 0s, 1.5s;
}
.title-mobile {
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;
  text-align: left;
  vertical-align: bottom;
  letter-spacing: 15%;
}

.move-img-1 {
  overflow: hidden;
  animation-name: move-1;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-delay: 5s; // <animation-duration> / 2
  visibility: hidden;
}
.move-img-2 {
  overflow: hidden;
  animation-name: move-1;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-delay: 20s;
  visibility: hidden;
}

@keyframes move-0 {
  0% {
    transform: translateY(250vh);
    visibility: visible;
  }

  100% {
    transform: translateY(150vh);
    visibility: visible;
  }
}

@keyframes move-0-after {
  0% {
    transform: translateY(150vh);
    visibility: visible;
  }

  100% {
    transform: translateY(0vh);
    visibility: visible;
  }
}

@keyframes move-1 {
  0% {
    transform: translateY(110vh);
    visibility: visible;
  }

  100% {
    transform: translateY(-140vh);
    visibility: visible;
  }
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;

  background-color: $ab-orange;
  animation-name: reveal-0, reveal-1;
  animation-iteration-count: 1;
}

@keyframes reveal-0 {
  0% {
    bottom: 0;
    visibility: visible;
  }

  100% {
    bottom: 0;
    visibility: visible;
  }
}

@keyframes reveal-1 {
  0% {
    bottom: 0;
    visibility: visible;
  }

  100% {
    bottom: 100%;
    visibility: hidden;
  }
}
