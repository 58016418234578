@import "../components/all/colors.scss";
.secciones-mobile {
  

  .section-title-mobile {
    color: $ab-orange;
    font-weight: 700;
    font-style: Bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 10%;
    text-align: left;
    vertical-align: top;
    letter-spacing: 10%;
  }
  .section-number-mobile {
    color: $ab-orange;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    padding-top: 2vh;
    vertical-align: top;
    text-align: left;
  }
  .column {
    margin-top: 15vh;
    float: left;
    width: 50%;
    padding: 0;
  }
  .row {
    display: flex;
    position: relative;
    border-bottom: 1px solid #b5b2b2 !important;
    padding-bottom: 5vh;
    top: 10vh;
    margin-left: 4vw;
    margin-right: 4vw;
    justify-content: space-between;
  }
}
