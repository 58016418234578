@import "../all/colors.scss";

.App::after {
  content: "";
  bottom: 0px;
  left: 0px;
  display: block;
  position: fixed;
  height: 485px;
  width: 15px;
  z-index: 22222;
  background: url("../all/scroll-capitulo.svg");
  margin-bottom: 20px;
  margin-left: 35px;
}

.descargar-capitulo {
  bottom: 0px;
  right: 0px;
  display: block;
  position: fixed;
  height: 362px;
  width: 26px;
  z-index: 22222;
  background: url("../all/descargar-capitulo.svg");
  margin-bottom: 20px;
  margin-right: 35px;
}

#nav-bar {
  background-color: rgba($color: #53575a, $alpha: 0.99);
  height: 10vh;
  z-index: 22222;
}

.btn-descargar {
  position: fixed;
  // top: 2.5vh;
  right: 13vw;
  background-color: transparent;
  border: 0px solid #000;
}

.btn-menu {
  position: fixed;
  // top: 2vh;
  right: 8vw;
  background-color: transparent;
  border: 0px solid #000;
}

.logo-abastible {
  position: fixed;
  // top: 2vh;
  left: 5vw;
  background-color: transparent;
  border: 0px solid #000;
}

.menu {
  //overflow-y: scroll; sale franja negra a la derecha
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  padding-left: 40%;
  padding-bottom: 10%;
  margin-right: 10%;
  align-self: stretch;
}

.menu-item {
  font-size: 1.5rem;
  padding-top: 20px !important;
  padding-bottom: 0px !important;
  margin: auto;
  flex: 1;
  width: 100%;
  font-size: 1.8rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.menu-item:after {
  content: "";
  height: 35px;
  display: block;
  margin: auto;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  padding-bottom: 0px !important;

  position: relative;
  bottom: 0;
}
.menu-item:hover:after {
  height: 35px;
  width: 100%;
  border-bottom: 5px solid $ab-white;
}

.cblock {
  color: red;
}

.bg-nav {
  position: sticky;
  height: 100vh;
  background: rgb(248, 82, 4);
  background: linear-gradient(
    180deg,
    rgb(161, 85, 50) 0%,
    rgba(83, 87, 90, 1) 100%
  );
  transform: translateX(100vw);
  .active {
    transform: translateX(0vw);
  }
}

.overlay-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 150;
  background: rgb(248, 82, 4);
  background: linear-gradient(
    180deg,
    rgba(248, 82, 4, 1) 0%,
    rgba(83, 87, 90, 1) 100%
  );
  transition: 0.1s;
  transform: translateX(100vw);
  * &.active {
    transform: translateX(0vw);
  }
}

.cursor-pointer {
  // display: none;
  transform: translateX(100vw);
  // animation-iteration-count: infinite;
  transition: 0.5s;

  &.active2 {
    animation-fill-mode: forwards;
    // transform: translateX(100vw);
    cursor: pointer;
    animation-name: none;
    animation-duration: 1s;
    animation-direction: normal;
    animation-name: Enter;
  }
}

@keyframes Enter {
  0% {
    transform: translateX(100vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0vw);
    opacity: 1;
  }
}

.cursor-pointer:nth-child(1) {
  animation-delay: 0.1s;
}
.cursor-pointer:nth-child(2) {
  animation-delay: 0.2s;
}
.cursor-pointer:nth-child(3) {
  animation-delay: 0.3s;
}
.cursor-pointer:nth-child(4) {
  animation-delay: 0.4s;
}
.cursor-pointer:nth-child(5) {
  animation-delay: 0.5s;
}
.cursor-pointer:nth-child(6) {
  animation-delay: 0.6s;
}
.cursor-pointer:nth-child(7) {
  animation-delay: 0.7s;
}
.cursor-pointer:nth-child(8) {
  animation-delay: 0.8s;
}
.cursor-pointer:nth-child(9) {
  animation-delay: 0.9s;
}
.cursor-pointer:nth-child(10) {
  animation-delay: 1s;
}
