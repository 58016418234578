@import "./all/colors.scss";

.final-section {
  height: 20vh;
  display: flex;
  align-items: center;
  color: $ab-white;
  justify-content: center;
  // background-color: rgba(181, 70, 19, 0.8);
  border-radius: 4px 4px 0 0;
  font-size: 2rem;
  font-weight: bolder;
  transition: 1s;
  z-index: 600;
  cursor: pointer;
  &:hover {
    height: 20vh;
    color: $ab-white;
    // background-color: rgba(181, 70, 19, 1);
    transform: scale(1.05);
  }
}

.final-container {
  z-index: 10;
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  color: rgba(181, 70, 19, 1);
  & > div:first-child {
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 4px solid rgba(181, 70, 19, 1);
    .final-image {
      transition: 0.5s;
      width: 80%;
      fill: rgba(181, 70, 19, 1);
    }
    &:hover .final-image {
      fill: $ab-white;
    }
    &:hover {
      transform: scale(1.1);
      border: $ab-white 4px solid;
    }
  }
  .text-prox {
    margin-top: 20px;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
  &:hover {
    color: $ab-white;
  }
}
