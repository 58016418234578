.slide2 {
  transition: 1s;
  background-repeat: no-repeat;
  background-size: cover;
  &:hover {
    transform: scale(1.05);
  }
  h2 {
    font-size: 24px;
    letter-spacing: 0.15em;
    font-weight: bold;
    line-height: 34px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
  .grad {
    background: rgb(0, 0, 0);
    &-right {
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.8) 40%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &-left {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.8) 40%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}
