@import "../all/colors.scss";
.box {
  color: $ab-white !important;
  margin: 0 0 11.75rem 5.9375rem;
  max-width: 20.0625rem;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    margin: 0px 0px 3.5rem 0px;
  }
  & p {
    margin-top: 3.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  & h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.15em;
  }
}

.box2 {
  color: $ab-white !important;
  margin: 0 0 11.75rem 5.9375rem;
  max-width: 23.4375rem;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    margin: 0px 0px 3.5rem 0px;
  }
  & p {
    margin-top: 3.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  & h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.15em;
  }
}

.box3 {
  color: $ab-white !important;
  margin: 0 5.3125rem 5rem 0;
  max-width: 23.4375rem;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    margin: 0px 0px 3.5rem 0px;
  }
  & p {
    margin-top: 1rem;
  }
  & h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.15em;
    font-weight: bold;
  }
}
